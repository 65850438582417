import { VBottomSheet } from 'vuetify/lib/components/VBottomSheet';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VBottomSheet,{staticClass:"cookie-banner",attrs:{"hide-overlay":"","max-width":"960","inset":""},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c(VCard,[_c(VCardTitle,{},[_vm._v(" Datenschutz ")]),_c(VCardText,{staticClass:"pb-1"},[_c('p',{staticClass:"mt-0"},[_vm._v(" Wir verwenden Cookies, um die Nutzererfahrung auf unserer Website zu verbessern. "),_c('a',{on:{"click":function($event){return _vm.$emit('showPrivacy')}}},[_vm._v("Datenschutzerklärung")])])]),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"default","text":""},on:{"click":function($event){_vm.showDialog = false; _vm.acceptCookies();}}},[_vm._v(" Nicht mehr anzeigen ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }