<template>
  <v-app id="app">
    <v-app-bar
      app
      elevate-on-scroll
      color="white"
      height="90px"
      class="px-0"
    >
      <v-container fluid class="py-0 px-3 px-md-8 fill-height">
        <a href="/" class="d-none d-md-flex">
          <v-img
            class="mr-2"
            src="@/assets/images/logo-sg.png"
            max-width="220"
            contain
          ></v-img>
        </a>
        <a href="/">
          <v-img
            class="mx-2"
            src="@/assets/images/logo-sg-flag.png"
            max-width="40"
            contain
          ></v-img>
        </a>
        <v-spacer></v-spacer>
        <div>
          <div class="text-center">
            <v-dialog
            v-model="languageSelection"
            max-width="460">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="default"
                  v-bind="attrs"
                  v-on="on"
                  prepe
                  outlined
                >
                  <v-icon left>
                    mdi-translate
                  </v-icon>
                  <span ref="selectLanguage">{{ selectLanguageText }}</span>
                </v-btn>
              </template>

              <v-card>
                <v-card-title>
                  <span>Sprache auswählen</span>
                </v-card-title>

            <v-card-text>
              <Translator class="notranslate" @on-country-click="languageSelection = false;" :countries="supportedLanguages"></Translator>
            </v-card-text>
              </v-card>
            </v-dialog>
          </div>
        </div>
        <v-btn
          v-for="link in $store.state.auth.navList"
          :key="link.path"
          :to="link.path"
          text
          class="mx-1"
          active-class="nav-btn-active"
        >
          {{ link.name }}
        </v-btn>
        <v-btn
          v-if="$store.getters.isAuthenticated"
          text
          class="mx-1"
          active-class="nav-btn-active"
          @click="logout()"
          >
          Logout
        </v-btn>
        <v-btn
          v-if="!$store.getters.isAuthenticated"
          text
          class="mx-1"
          active-class="nav-btn-active"
          to="/"
        >
          Kursangebot
        </v-btn>
        <v-btn
          v-if="!$store.getters.isAuthenticated"
          text
          class="mx-1 d-none d-md-flex"
          active-class="nav-btn-active"
          to="/login"
          >
          Login
        </v-btn>
      </v-container>
    </v-app-bar>
    <v-main class="">
        <v-container class="py-0" v-if="isTranslated">
          <v-row>
            <v-col class="pt-6 pb-2 subtitle-2">
              <v-icon class="pr-2">
                mdi-translate-variant
              </v-icon>
              Bemerkung: Machinelle Übersetzung
            </v-col>
          </v-row>
        </v-container>
        <router-view></router-view>
    </v-main>
    <v-footer>
      <v-btn
        color=""
        text
        small
        class="mr-2"
        @click="sheet = !sheet"
      >
        Impressum
      </v-btn>
      <v-btn
        color=""
        text
        small
        class="mr-2"
        @click="sheetPolicy = !sheetPolicy"
      >
        Datenschutz
      </v-btn>
    </v-footer>

    <div class="">
      <v-bottom-sheet
        v-model="sheet"
        scrollable
      >
          <legal-notice @closeSheet="sheet=false"></legal-notice>
      </v-bottom-sheet>
      <v-bottom-sheet
        v-model="sheetPolicy"
        scrollable
      >
        <privacy-policy @closeSheet="sheetPolicy=false"></privacy-policy>
      </v-bottom-sheet>
    </div>

    <!-- global snackbar for alerts -->
    <v-snackbar v-model="snackbar" timeout="2500" :color="snackbarColor"
    >{{ snackbarMessage }}</v-snackbar>
    <cookie-banner @showPrivacy="sheetPolicy=true"></cookie-banner>
  </v-app>
</template>

<script>
import EventBus, { ACTIONS } from './events/index'
import LegalNotice from './components/LegalNotice'
import store from './store'
import { Translator } from 'vue-google-translate'
import PrivacyPolicy from "./components/PrivacyPolicy.vue";

export default {
  components: {
    'legal-notice': LegalNotice,
    'privacy-policy': PrivacyPolicy,
    Translator
  },
  data: () => ({
    snackbar: false,
    snackbarMessage: '',
    snackbarColor: '',
    links: [],
    isTranslated: false,
    selectLanguageText: 'Sprache',
    sheet: false,
    sheetPolicy: false,
    languageSelection: false,
    supportedLanguages: [
      {
        code: 'en|de',
        title: 'Deutsch',
      },
      {
        code: 'en|sq',
        title: 'Shqip',
      },
      {
        code: 'en|hr',
        title: 'Hrvatski',
      },
      {
        code: 'en|en',
        title: 'English',
      },
      {
        code: 'en|it',
        title: 'Italiano',
      },
      {
        code: 'en|pt',
        title: 'Português',
      },
      {
        code: 'en|es',
        title: 'Español',
      },
      {
        code: 'en|fr',
        title: 'Français',
      },
      {
        code: 'en|tr',
        title: 'Türkçe'
      },
      {
        code: 'en|ta',
        title: 'தமிழ்',
      },
      {
        code: 'en|ru',
        title: 'Русский',
      },
      {
        code: 'en|ar',
        title: 'العربية',
      },
      {
        code: 'en|ku',
        title: 'کوردی',
      },
      {
        code: 'en|ti',
        title: 'Tigrinya',
      },
      {
        code: 'en|fa',
        title: 'فارسی',
      },
      {
        code: 'en|so',
        title: 'Af-Soomali',
      },
      {
        code: 'en|uk',
        title: 'Українська',
      }
    ]
  }),
  created () {

  },
  methods: {
    async logout () {
      await this.$store.dispatch('LogOut')
      this.$router.push('/login')
    }
  },
  mounted () {
    EventBus.$on(ACTIONS.SNACKBAR, message => {
      this.snackbarMessage = message.message
      this.snackbarColor = message.color
      this.snackbar = true
    })

    window.setInterval(() => {
      if (this.$refs.selectLanguage) {
        this.isTranslated = (this.$refs.selectLanguage.innerHTML !== this.selectLanguageText)
      }
    }, 1000)
  }
}
</script>

<style lang="scss">
  @import "src/style/variables";
  @import "src/style/default";

  .v-btn.nav-btn-active {

    background-color: #fff;

    &::before {
      background-color: #fff;
    }
  }

  header {
    .v-toolbar__content {
      padding: 0;
    }
  }


  body {
    top: 0 !important;
  }
  /* get rids of the banner at the bottom of the web page */
  .skiptranslate {
    display: none !important;
  }

  .language-item {
    img {
      display: none;
    }
  }


</style>
