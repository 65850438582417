<template>
  <v-container class="fill-height">
    <v-row class="mt-0 mt-md-3">
      <v-col class="px-6 d-flex d-md-none" cols="12" md="12">
        <h3>Deutschkurse Kanton St. Gallen</h3>
      </v-col>
      <v-col class="white px-8 pl-md-4 px-md-0 pr-md-6" cols="12" md="4" lg="4" xl="4">
        <v-row>
          <v-col class="grey-dark rounded-t d-none d-md-flex">
            <div class="py-0 px-2">
              <h5 class="white--text">
                <v-icon color="white" class="mr-4 ml-1">mdi-filter-outline</v-icon>
                Kurse filtern
              </h5>
            </div>
          </v-col>
          <v-col class="grey-dark rounded py-1 d-md-none">
            <div class="py-0 pr-3 d-flex justify-space-between" style="">
              <h5 class="white--text"  @click="showFilter = !showFilter; showMapFilter = false;">
                <v-btn icon @click="">
                  <v-icon color="white" class="">
                    {{ `mdi-${(showFilter === true) ? 'chevron-up' : 'chevron-down'}` }}
                  </v-icon>
                </v-btn>
                Filtern
              </h5>
              <v-divider vertical class="my-1" style="background-color: white; border-width: 1px; border-radius: 2px"></v-divider>
              <h5 class="white--text" @click="toggleMap(); (showMapFilter) ? showFilter = false : ''" >
                <v-btn icon  v-if="showMapFilter"  color="primary"
                       class="" style="">
                  <v-icon class="">mdi-map-search-outline</v-icon>
                </v-btn>
                <v-btn icon v-else
                       class="v-btn--filter" style="" color="white">
                  <v-icon class="">mdi-map-search-outline</v-icon>
                </v-btn>
                <span>Suchen</span>
              </h5>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col :class="{ 'py-6': true, 'px-3': true, 'px-md-6': true, 'elevation-1': true, 'rounded-b': true, 'd-none': !showFilter, 'd-md-block': true }"
                 ref="filterMenu">
            <v-row>
              <v-expansion-panels
                v-model="filterPanel"
                class="px-4 filter-panels"
                multiple
              >
                <!-- location filter -->
                <v-expansion-panel class="mt-5">
                  <v-expansion-panel-header class="grey-light filter-panel-header py-0">
                    {{ eventFilters.zipcode.title }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="">
                    <v-autocomplete
                      v-if="true"
                      auto-select-first
                      class="mt-3"
                      v-model="eventFilters.zipcode.filters[0].value"
                      :label="eventFilters.zipcode.filters[0].label"
                      :error="eventFilters.zipcode.filters[0].error"
                      :error-messages="eventFilters.zipcode.filters[0].errorMessages"
                      :placeholder="eventFilters.zipcode.filters[0].placeholder"
                      prepend-icon="mdi-map-marker"
                      :items="ZipCodes.getStGallenCitiesByZipCode()"
                      item-value="value"
                      item-text="text"
                    >
                      <template v-slot:item="{ parent, item }">
                          <v-list-item-content class="notranslate"
                              v-html="item.text"
                          ></v-list-item-content>
                      </template>
                    </v-autocomplete>

                    <v-text-field
                      v-else
                      class="mt-3"
                      v-model="eventFilters.zipcode.filters[0].value"
                      :label="eventFilters.zipcode.filters[0].label"
                      :error="eventFilters.zipcode.filters[0].error"
                      :error-messages="eventFilters.zipcode.filters[0].errorMessages"
                      :placeholder="eventFilters.zipcode.filters[0].placeholder"
                      prepend-icon="mdi-map-marker"
                    ></v-text-field>
                    <v-card-actions class="pa-0 mt-1">
                      <v-btn @click="applyZipCodeFilter()" class="mt-3" block color="primary">
                        Anwenden
                      </v-btn>
                    </v-card-actions>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <!-- event filters -->
                <v-expansion-panel class="mt-5">
                  <v-expansion-panel-header class="grey-light filter-panel-header py-0 elevation-0">
                    {{ eventFilters.level.title }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div v-for="filter in eventFilters.level.filters" :key="filter.name">
                          <v-checkbox
                            v-model="filter.active"
                            :label="filter.label"
                            dense
                            hide-details
                            class="mt-1"
                          >
                            <template v-slot:append>
                              <v-tooltip left>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon slot="activator"
                                          v-bind="attrs"
                                          v-on="on"
                                          small
                                          dense
                                  >mdi-information
                                  </v-icon>
                                </template>
                                <span>{{ filter.tooltip }}</span>
                              </v-tooltip>
                            </template>
                          </v-checkbox>
                    </div>
                    <v-card-actions class="pa-0 mt-1">
                      <v-btn @click="applyFilter()" class="mt-3" block color="primary">
                        Anwenden
                      </v-btn>
                    </v-card-actions>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel class="mt-5">
                  <v-expansion-panel-header class="grey-light filter-panel-header py-0">
                    {{ eventFilters.weekday.title }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div v-for="filter in eventFilters.weekday.filters" :key="filter.name">
                      <v-checkbox
                        v-model="filter.active"
                        :label="filter.label"
                        dense
                        hide-details
                        class="mt-1"
                      ></v-checkbox>
                    </div>
                    <v-card-actions class="pa-0 mt-1">
                      <v-btn @click="applyFilter()" class="mt-3" block color="primary">
                        Anwenden
                      </v-btn>
                    </v-card-actions>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel class="mt-5">
                  <v-expansion-panel-header class="filter-panel-header grey-light py-0">
                    {{ eventFilters.intensity.title }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div v-for="filter in eventFilters.intensity.filters" :key="filter.value">
                      <v-checkbox
                        v-model="filter.active"
                        :label="filter.label"
                        dense
                        hide-details
                        class="mt-1"
                      >
                      </v-checkbox>
                    </div>
                    <v-card-actions class="pa-0 mt-1">
                      <v-btn @click="applyFilter()" class="mt-3" block color="primary">
                        Anwenden
                      </v-btn>
                    </v-card-actions>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel class="mt-5">
                  <v-expansion-panel-header class="filter-panel-header grey-light py-0">
                    {{ eventFilters.timeofday.title }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="">
                    <div v-for="filter in eventFilters.timeofday.filters" :key="filter.name">

                          <v-checkbox
                            v-model="filter.active"
                            :label="filter.label"
                            dense
                            hide-details
                            class="mt-1"
                          >
                            <template v-slot:append>
                              <v-tooltip left>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon slot="activator"
                                          v-bind="attrs"
                                          v-on="on"
                                          small
                                          dense
                                  >mdi-information
                                  </v-icon>
                                </template>
                                <span>{{ filter.tooltip }}</span>
                              </v-tooltip>
                            </template>
                          </v-checkbox>
                    </div>
                    <v-card-actions class="pa-0 mt-1">
                      <v-btn @click="applyFilter()" class="mt-3" block color="primary">
                        Anwenden
                      </v-btn>
                    </v-card-actions>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel class="mt-5">
                  <v-expansion-panel-header class="grey-light filter-panel-header py-0">
                    {{ eventFilters.categories.title }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="">
                    <div v-for="filter in eventFilters.categories.filters" :key="filter.name">
                      <v-checkbox
                        v-model="filter.active"
                        :label="filter.label"
                        dense
                        hide-details
                        class="mt-1"
                      >
                        <template v-slot:append>
                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon slot="activator"
                                      v-bind="attrs"
                                      v-on="on"
                                      small
                                      dense
                              >mdi-information
                              </v-icon>
                            </template>
                            <span>{{ filter.description }}</span>
                          </v-tooltip>
                        </template>
                      </v-checkbox>
                    </div>
                    <v-card-actions class="pa-0 mt-1">
                      <v-btn @click="applyFilter()" class="mt-3" block color="primary">
                        Anwenden
                      </v-btn>
                    </v-card-actions>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel class="mt-5">
                  <v-expansion-panel-header class="grey-light filter-panel-header py-0">
                    {{ eventFilters.eventdate.title }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div v-for="filter in eventFilters.eventdate.filters" :key="filter.name">
                      <v-menu
                        v-model="filter.menu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="filter.valueFormatted"
                            :label="filter.label"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          color="primary"
                          v-model="filter.value"
                          @input="selectDate(filter)"
                        ></v-date-picker>
                      </v-menu>
                    </div>
                    <v-card-actions class="pa-0 mt-1">
                      <v-btn @click="applyFilter()" class="mt-3" block color="primary">
                        Anwenden
                      </v-btn>
                    </v-card-actions>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-row>
            <v-row>
              <v-col class="px-4">
                <v-btn
                  outlined
                  block
                  color="primary"
                  class="mt-5 overflow-x-hidden"
                  @click="resetFilter()"
                >Alle Filter zurücksetzen
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="mt-4 rounded d-none d-md-flex px-0">
            <v-card width="100%" class="pa-2">
              <v-card-title>
                <div class="d-flex justify-start event-card-text">
                  <v-icon color="primary" left>mdi-information-outline</v-icon>
                  <h5>Finanzielle Unterstützung Deutschkurs</h5>
                </div>
              </v-card-title>
              <v-card-text>
                Je nach Einkommen erhalten Sie eine Preisreduktion für den Deutschkurs.
                Den Antrag dazu können Sie mit der Kurslehrerin oder dem Kurslehrer besprechen und ausfüllen.
              </v-card-text>
              <v-card-actions>
                <v-btn
                  color="primary lighten-2"
                  text
                  @click="showDownloads = !showDownloads"
                >
                  Downloads
                </v-btn>

                <v-spacer></v-spacer>

                <v-btn
                  icon
                  @click="showDownloads = !showDownloads"
                >
                  <v-icon>{{ showDownloads ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                </v-btn>
              </v-card-actions>
              <v-expand-transition>
                <div v-show="showDownloads">
                  <v-divider></v-divider>
                  <v-card-text class="notranslate">
                    <v-list dense>
                      <v-list-item class="px-0">
                        <v-list-item-icon class="ml-0 mr-1">
                          <v-icon class="" color="primary" left>mdi-file-pdf-box</v-icon>
                        </v-list-item-icon>
                        <a target="_blank"
                           href="https://www.sg.ch/content/dam/sgch/gesundheit-soziales/soziales/integration/deutschkurse/Albanisch.pdf">
                          Mësoni gjermanisht - ja vlen! (albanisch)
                        </a>
                      </v-list-item>
                      <v-list-item class="px-0">
                        <v-list-item-icon class="ml-0 mr-1">
                          <v-icon class="" color="primary" left>mdi-file-pdf-box</v-icon>
                        </v-list-item-icon>
                        <a target="_blank"
                           href="https://www.sg.ch/content/dam/sgch/gesundheit-soziales/soziales/integration/deutschkurse/Arabisch.pdf">
                          تعلم األلمانية - فهي ذات جدوى! (arabisch)
                        </a>
                      </v-list-item>
                      <v-list-item class="px-0">
                        <v-list-item-icon class="ml-0 mr-1">
                          <v-icon class="" color="primary" left>mdi-file-pdf-box</v-icon>
                        </v-list-item-icon>
                        <a target="_blank"
                           href="https://www.sg.ch/content/dam/sgch/gesundheit-soziales/soziales/integration/deutschkurse/Bosnisch.pdf">
                          Učite njemački – isplati se! (bosnisch)
                        </a>
                      </v-list-item>
                      <v-list-item class="px-0">
                        <v-list-item-icon class="ml-0 mr-1">
                          <v-icon class="" color="primary" left>mdi-file-pdf-box</v-icon>
                        </v-list-item-icon>
                        <a target="_blank"
                           href="https://www.sg.ch/content/dam/sgch/gesundheit-soziales/soziales/integration/deutschkurse/Deutsch.pdf">
                          Lernen Sie Deutsch – es lohnt sich! (deutsch)
                        </a>
                      </v-list-item>
                      <v-list-item class="px-0">
                        <v-list-item-icon class="ml-0 mr-1">
                          <v-icon class="" color="primary" left>mdi-file-pdf-box</v-icon>
                        </v-list-item-icon>
                        <a target="_blank"
                           href="https://www.sg.ch/content/dam/sgch/gesundheit-soziales/soziales/integration/deutschkurse/Englisch.pdf">
                          Learn German – it is worth it! (englisch)
                        </a>
                      </v-list-item>
                      <v-list-item class="px-0">
                        <v-list-item-icon class="ml-0 mr-1">
                          <v-icon class="" color="primary" left>mdi-file-pdf-box</v-icon>
                        </v-list-item-icon>
                        <a target="_blank"
                           href="https://www.sg.ch/content/dam/sgch/gesundheit-soziales/soziales/integration/deutschkurse/Farsi.pdf">
                          آلمانی بیاموزید - ارزشش را دارد! (farsi)
                        </a>
                      </v-list-item>
                      <v-list-item class="px-0">
                        <v-list-item-icon class="ml-0 mr-1">
                          <v-icon class="" color="primary" left>mdi-file-pdf-box</v-icon>
                        </v-list-item-icon>
                        <a target="_blank"
                           href="https://www.sg.ch/content/dam/sgch/gesundheit-soziales/soziales/integration/deutschkurse/Franz%C3%B6sisch.pdf">
                          Apprenez l’allemand – cela vaut la peine! (französisch)
                        </a>
                      </v-list-item>
                      <v-list-item class="px-0">
                        <v-list-item-icon class="ml-0 mr-1">
                          <v-icon class="" color="primary" left>mdi-file-pdf-box</v-icon>
                        </v-list-item-icon>
                        <a target="_blank"
                           href="https://www.sg.ch/content/dam/sgch/gesundheit-soziales/soziales/integration/deutschkurse/Italienisch.pdf">
                          Imparate il tedesco – ne vale la pena! (italienisch)
                        </a>
                      </v-list-item>
                      <v-list-item class="px-0">
                        <v-list-item-icon class="ml-0 mr-1">
                          <v-icon class="" color="primary" left>mdi-file-pdf-box</v-icon>
                        </v-list-item-icon>
                        <a target="_blank"
                           href="https://www.sg.ch/content/dam/sgch/gesundheit-soziales/soziales/integration/deutschkurse/Kurmanji.pdf">
                          Elmanî hîn bibin – ew hêjayê hînbûnê ye. (kurmanji)
                        </a>
                      </v-list-item>
                      <v-list-item class="px-0">
                        <v-list-item-icon class="ml-0 mr-1">
                          <v-icon class="" color="primary" left>mdi-file-pdf-box</v-icon>
                        </v-list-item-icon>
                        <a target="_blank"
                           href="https://www.sg.ch/content/dam/sgch/gesundheit-soziales/soziales/integration/deutschkurse/Portugiesisch.pdf">
                          Aprenda Alemão – Vale a Pena! (portugiesisch)
                        </a>
                      </v-list-item>
                      <v-list-item class="px-0">
                        <v-list-item-icon class="ml-0 mr-1">
                          <v-icon class="" color="primary" left>mdi-file-pdf-box</v-icon>
                        </v-list-item-icon>
                        <a target="_blank"
                           href="https://www.sg.ch/content/dam/sgch/gesundheit-soziales/soziales/integration/deutschkurse/Somali.pdf">
                          Baro Jarmalka–waa muhiim! (somali)
                        </a>
                      </v-list-item>
                      <v-list-item class="px-0">
                        <v-list-item-icon class="ml-0 mr-1">
                          <v-icon class="" color="primary" left>mdi-file-pdf-box</v-icon>
                        </v-list-item-icon>
                        <a target="_blank"
                           href="https://www.sg.ch/content/dam/sgch/gesundheit-soziales/soziales/integration/deutschkurse/Spanisch.pdf">
                          Aprenda alemán, ¡merece la pena! (spanisch)
                        </a>
                      </v-list-item>
                      <v-list-item class="px-0">
                        <v-list-item-icon class="ml-0 mr-3">
                          <v-icon class="" color="primary" left>mdi-file-pdf-box</v-icon>
                        </v-list-item-icon>
                        <a target="_blank"
                           href="https://www.sg.ch/content/dam/sgch/gesundheit-soziales/soziales/integration/deutschkurse/Tamil.pdf">
                          ஜெர்மன் ஜமொழியயக் கற்கவும் – அது மதிப்பொனது! (tamil)
                        </a>
                      </v-list-item>
                      <v-list-item class="px-0">
                        <v-list-item-icon class="ml-0 mr-1">
                          <v-icon class="" color="primary" left>mdi-file-pdf-box</v-icon>
                        </v-list-item-icon>
                        <a target="_blank"
                           href="https://www.sg.ch/content/dam/sgch/gesundheit-soziales/soziales/integration/deutschkurse/Tigrinya.pdf">
                          ጀርመንኛ ተመሃር – ይረብሕ እዩ! (tigrinya)
                        </a>
                      </v-list-item>
                      <v-list-item class="px-0">
                        <v-list-item-icon class="ml-0 mr-1">
                          <v-icon class="" color="primary" left>mdi-file-pdf-box</v-icon>
                        </v-list-item-icon>
                        <a target="_blank"
                           href="https://www.sg.ch/content/dam/sgch/gesundheit-soziales/soziales/integration/deutschkurse/T%C3%BCrkisch.pdf">
                          Almanca öğrenin - buna değer! (türkisch)
                        </a>
                      </v-list-item>
                    </v-list>
                  </v-card-text>

                </div>
              </v-expand-transition>
            </v-card>
          </v-col>
        </v-row>

      </v-col>
      <v-col cols="12" md="8" lg="8" xl="8" class="pt-0 pr-8 pl-8 pl-md-8 pl-lg-16">
        <v-row class="my-0">
          <v-col cols="12" md="8" class="mb-0 pl-0 pt-0 d-none d-md-block">
            <h1 class="align-self-center" style="line-height: 1.1">Deutschkurse Kanton St. Gallen</h1>
            <p class="mt-0">Angebote von akkreditierten Kursanbietenden</p>
          </v-col>
          <v-col cols="12" md="4" class="pt-0 pr-0 d-none d-md-flex">
            <v-btn outlined v-if="showMapFilter" @click="toggleMap()" color="primary"
                   class="float-right pr-0 mt-3 mt-md-0" plain style="font-size: 15px">
              <v-icon class="mr-2">mdi-map-search-outline</v-icon>
              Kurse in meiner Nähe
              <v-icon class="mx-2">mdi-chevron-up</v-icon>
            </v-btn>
            <v-btn outlined color="#404040" v-else @click="toggleMap()"
                   class="float-right pr-0 v-btn--filter mt-3 mt-md-0" plain style="font-size: 15px">
              <v-icon class="mr-2">mdi-map-search-outline</v-icon>
              Kurse in meiner Nähe
              <v-icon class="mx-2">mdi-chevron-down</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-expand-transition>
          <v-row class="my-0" id="mapRow" v-show="showMapFilter">
            <v-col cols="12" md="12" lg="8" class="py-0 pl-0 pr-0 pr-md-2 rounded">
              <div id="map" class="google-map" ref="googleMap"></div>
            </v-col>

            <v-col class="pl-md-6" cols="12" md="12" lg="4">
              <v-row class="d-none d-md-flex">
                <v-col class="pa-0 mt-sm-3 mt-lg-0">
                  <p class="mb-0">Kursanbietende in diesem Bereich</p>
                  <p class="subtitle-2 font-weight-light">Klicken um Kurse an diesem Ort anzuzeigen.</p>
                </v-col>
              </v-row>
              <v-row class="mt-0 d-none d-md-flex">
                <v-col class="pa-0">
                  <v-card v-if="visibleLocations.length > 0" class="elevation-0 mt-2">
                    <v-list class="py-0 elevation-0" dense>
                      <v-list-item-group
                        v-model="selectedItem"
                        color="primary"
                        dense
                        class="mt-0"
                      >
                        <v-list-item v-for="location in visibleLocations.slice(0,visibleLocationsInList)"
                                     :key="location.id" @click="filterSingleLocation(null, location.id)">
                          <v-list-item-icon>
                            <v-icon>mdi-map-marker</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title class="notranslate">{{ location.Operator.name }}</v-list-item-title>
                            <v-list-item-subtitle class="notranslate">{{ location.address }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="visibleLocations.length >= (visibleLocationsInList + 1)">
                          <v-list-item-icon>
                            <v-icon>mdi-map-marker</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title v-if="visibleLocations.length === visibleLocationsInList + 1">1 weiterer
                              Kursort in diesem Bereich...
                            </v-list-item-title>
                            <v-list-item-title v-else>{{ visibleLocations.length - visibleLocationsInList }} weitere
                              Kursorte in diesem Bereich...
                            </v-list-item-title>
                            <v-list-item-subtitle></v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                    <v-spacer></v-spacer>
                  </v-card>
                  <p class="mt-4" v-else>Keine Kursorte im aktuellen Kartenbereich.</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pa-0 pt-0 pt-md-4 mt-auto">
                  <v-btn color="primary" block @click="filterVisibleLocations()">Im aktuellen Bereich suchen</v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-expand-transition>
        <v-row>
          <v-col class="filter-chips mt-5 pl-0">
          <span v-for="(filterObj, name) in appliedFilters" :key="filterObj.title">
            <span v-if="filterObj.filters.length > 0 && filterObj.filters[0] ">
              <span v-if="filterObj.title === 'Kursort'">
                <v-chip
                  v-if="filterObj.title === 'Kursort'"
                  color="secondary"
                  class="mr-3 mb-3"
                  label
                  close
                  @click:close="removeLocationFilter()">
                  <span v-if="filterObj.filters.length > 1">Ausgewählter Bereich</span>
                  <span v-else>Ausgewählter Kursort</span>
                </v-chip>
              </span>
              <span v-else v-for="filter in filterObj.filters" :key="filter.label">
                <v-chip
                  color="secondary"
                  class="mr-3 mb-3"
                  label
                  close
                  @click:close="removeFilter(filterObj, filter, name)">
                  <span v-if="filterObj.title === 'Kursdatum'">{{ filter.label }}: {{ filter.valueFormatted }}</span>
                  <span v-else><span v-if="filterObj.title">{{ filterObj.title }}: </span>{{ filter.label }}</span>
                </v-chip>
              </span>
            </span>
          </span>
            <span>
              <v-menu
                  offset-y
              >
                <template v-slot:activator="{ attrs, on }">
                  <v-chip
                    v-if="sortBy.field"
                    color="secondary"
                    outlined
                    class="mr-3 mb-3"
                    label
                    v-on="on"
                    v-bind="attrs"
                    >
                    <v-icon left >
                      {{`mdi-${sortBy.desc === true ? 'chevron-down' : 'chevron-up'}`}}
                    </v-icon>
                    <span>Sortieren nach: {{ sortText[sortBy.field].text }}</span>
                  </v-chip>
                </template>
                <v-list>
                  <v-list-item
                      v-for="(item) in sortText"
                      :key="item.value"
                      link
                      @click="sortEvents(item.value)"
                  >
                    <v-list-item-icon class="mr-2">
                        <v-icon small>mdi-sort</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title >
                      {{ item.text }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              </span>
          </v-col>
        </v-row>
        <v-row class="" ref="eventListStart">
          <v-progress-linear
            indeterminate
            v-if="listLoading"
            color="blue darken-2">
          </v-progress-linear>
          <v-col class="pa-0 justify-center" v-if="eventList.length === 0 && !listLoading && !apiError">
            <v-list>
              <v-list-item>
                <p>Es wurden keine Kurse für den aktuellen Filter gefunden.</p>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col class="pa-0 justify-center" v-if="apiError && !listLoading">
            <v-list>
              <v-list-item>
                <p>Die Kursedatenbank ist momentan nicht erreichbar. Versuchen Sie es zu einem späteren Zeitpunkt noch
                  einmal.</p>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col class="pa-0" v-if="!listLoading">

            <!-- new event list proposal with cards -->
            <v-row v-for="event in visibleEventList" :key="event.id">
              <v-col>
                <v-card :ripple="false" @click="isClicked(event) === true ? clickedEvent = null : clickedEvent = event" :class="isClicked(event) === true ? 'event-card-active event-card pa-2 pa-md-4' : 'event-card pa-2 pa-md-4'">
                  <v-card-title>
                    <v-row>
                      <v-col cols="10">
                        <p class="mb-0 event-card-title">
                          {{ event.title }}
                        </p>
                        <p class="event-card-subtitle notranslate">
                          {{ event.EventLocation.Operator.name }}
                        </p>
                      </v-col>
                      <v-col cols="2">
                        <v-img style="margin-left: auto" max-width="55"
                               :src="logoBase + event.EventLocation.Operator.logo" contain max-height="150"
                               class=""></v-img>
                      </v-col>
                    </v-row>
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" md="6" class="py-0">
                        <div class="d-flex justify-start event-card-text pb-1">
                          <v-icon color="primary" small left>mdi-map-marker-outline</v-icon>
                          <span v-if="event.EventLocation.shortAddress !== 'Onlinekurs'" class="notranslate">{{ event.EventLocation.shortAddress }}</span>
                          <span v-else>{{ event.EventLocation.shortAddress }}</span>
                        </div>
                        <div class="d-flex justify-start event-card-text pb-1">
                          <v-icon color="primary" left small>mdi-calendar</v-icon>
                          <span v-if="event.startDate && event.endDate && event.startDate !== '0000-00-00' && event.endDate !== '0000-00-00'"
                                style="">
                                  {{ formatDate(event.startDate) }} - {{ formatDate(event.endDate) }}
                                </span>
                          <span v-else>
                                  fortlaufend
                                </span>
                        </div>
                        <div v-if="event.totalCosts && event.totalCosts > 0" class="d-flex justify-start event-card-text">
                          <v-icon color="primary" left small>mdi-hand-coin-outline</v-icon>
                          <span v-if="event.multiCourseLink">ab&nbsp;</span>CHF {{
                            Format.numberWithDelimiter(event.totalCosts)
                          }}.00 <span class=""
                                      v-if="SubsidyCalculation.getCostWithSubsidy(event.totalCosts, event.lessonCount, event.courseLevel) > 0">
                                   &nbsp;| CHF {{
                            Format.numberWithDelimiter(SubsidyCalculation.getCostWithSubsidy(event.totalCosts, event.lessonCount, event.courseLevel))
                          }}.00
                                  <v-menu offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                       <v-icon
                                           v-on="on"
                                           v-bind="attrs"
                                           small
                                           color=""
                                           dense
                                           style=""
                                           class="ml-1 mb-1"
                                           @click="infoSubsidy = !infoSubsidy"
                                       >mdi-information</v-icon>
                                    </template>
                                    <v-card>
                                      <v-card-text>
                                        Wenn eine Subventionierung bewilligt wird erhält man<br>
                                        für diesen Kurs einen Subventionsbetrag von CHF
                                        <span v-if="event.courseLevel === 0">{{ SubsidyCalculation.getSubsidyAmountA() }}.-</span>
                                        <span v-else>{{ SubsidyCalculation.getSubsidyAmountB() }}.-</span> pro Lektion.<br>
                                        <a
                                            href="https://www.sg.ch/gesundheit-soziales/soziales/integration/sprache/informationen-deutschfoerderung.html"
                                            target="_blank">Weitere Informationen zur Subventionierung</a>
                                      </v-card-text>
                                    </v-card>
                                  </v-menu>
                                </span>
                        </div>
                      </v-col>
                      <v-col cols="12" md="6" class="py-0">
                          <div style="border-spacing: 0" class="event-card-text" v-if="event.EventWindows && event.EventWindows.length > 0">
                            <div v-for="(window, idx) in event.EventWindows" :key="window.id" class="d-flex event-card-text pb-0">
                              <div class="d-flex align-self-center" style="width: 24px">
                                <v-icon v-if="idx === 0" color="primary" small left>mdi-clock-outline</v-icon>
                              </div>
                              <div class="d-flex align-center overflow-x-hidden" style="width: 102px">
                                {{ fullDayStr(window.weekday) }}
                              </div>
                              <div class="d-flex align-end overflow-x-hidden">
                                {{ window.timeStart }} - {{ window.timeEnd }}
                              </div>
                            </div>
                          </div>
                          <div v-else class="event-card-text">
                            <v-icon color="primary" left small>mdi-clock-outline</v-icon>siehe Kurswebseite
                          </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions class="pb-4" style="margin-bottom: -12px">


                    <v-btn
                      :color="isClicked(event) === true ? 'primary' : ''"
                      plain
                      class="" style=""
                    >
                      Kursbeschreibung
                      <v-icon right>{{ isClicked(event) ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                    </v-btn>
                  </v-card-actions>
                  <v-expand-transition>
                    <div v-show="isClicked(event)">
                      <v-card-text class="pt-0">
                        {{ event.description }}
                      </v-card-text>
                      <v-card-actions class="pt-0 pb-4 px-4">
                        <a v-if="event.multiCourseLink && event.externalUrl" :href="generateUrl(event.externalUrl)" target="_blank"><v-btn outlined color="primary" >zu den Kursen <v-icon small class="ml-2">mdi-open-in-new</v-icon></v-btn></a>
                        <span v-else>
                          <a v-if="event.externalUrl" :href="generateUrl(event.externalUrl)" target="_blank"><v-btn outlined color="primary" ><v-icon small class="mr-2">mdi-open-in-new</v-icon><span v-if="!isMobile()">zur </span>Kurswebseite </v-btn></a>
                          <a v-else-if="event.EventLocation.Operator.websiteUrl" :href="generateUrl(event.EventLocation.Operator.websiteUrl)" target="_blank"><v-btn outlined color="primary" ><v-icon small class="mr-2">mdi-open-in-new</v-icon><span v-if="!isMobile()">zur </span>Kurswebseite</v-btn></a>
                        </span>

                        <v-btn color="primary" class="ml-4" @click="showEventDetails(event)">
                          mehr Details
                        </v-btn>
                      </v-card-actions>
                    </div>
                  </v-expand-transition>
                </v-card>
              </v-col>
            </v-row>
            <!-- end event list with cards -->
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-pagination
              v-model="page"
              class="my-4 elevation-0 notranslate"
              :total-visible="10"
              :length="Math.ceil(eventList.length / eventsPerPage)"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import axios from 'axios'
import {Loader} from '@googlemaps/js-api-loader'
import {Format, SubsidyCalculation} from "../utils/utils";
import {ZipCodes} from "../utils/zipcodes";

export default {
  name: 'Home',
  components: {},
  data() {
    return {
      eventActions: {
        showEventDialog: false,
        confirmDeleteEvent: false
      },
      infoSubsidy: false,
      logoBase: process.env.VUE_APP_AZURE_BLOB_BASE,
      page: 1,
      sortBy: {
        field: 'title',
        desc: false
      },
      selectedLanguage: 'de',
      languages: [
        {
          name: 'Deutsch', code: 'de'
        },
        {
          name: 'Espanol', code: 'es'
        }
      ],
      eventsPerPage: 20,
      showFilter: false,
      visibleLocationsInList: 4,
      selectedItem: null,
      eventPanel: [],
      eventList: [],
      showDownloads: false,
      apiError: false,
      listLoading: false,
      showMapFilter: false,
      google: null,
      map: null,
      infoWindow: null,
      markers: [],
      locationList: [],
      geocoder: null,
      appliedTags: [],
      breakpoint: this.$vuetify.breakpoint.name,
      appliedFilters: {
        location: {
          title: 'Kursort',
          filters: []
        }
      },
      eventFilters: {
        level: {
          title: 'Sprachniveau',
          filters: [
            {
              name: 'A0',
              label: 'A0',
              active: false,
              tooltip: 'Alphabetisierung',
              value: 0
            },
            {
              name: 'A1',
              label: 'A1',
              active: false,
              tooltip: 'Anfänger*innen',
              value: 1
            },
            {
              name: 'A2',
              label: 'A2',
              active: false,
              tooltip: 'Grundlegende Kenntnisse',
              value: 2
            },
            {
              name: 'B1',
              label: 'B1',
              active: false,
              tooltip: 'Fortgeschrittene Sprachverwendung',
              value: 3
            },
            {
              name: 'B2',
              label: 'B2',
              active: false,
              tooltip: 'Selbständige Sprachverwendung',
              value: 4
            },
            {
              name: 'C1',
              label: 'C1',
              active: false,
              tooltip: 'Fachkundige Sprachkenntnisse',
              value: 5
            },
            {
              name: 'C2',
              label: 'C2',
              active: false,
              tooltip: 'Beherrschung der Sprache',
              value: 6
            }
          ]
        },
        weekday: {
          title: 'Kurstag',
          filters: [
            {
              name: 'mon',
              label: 'Montag',
              active: false,
              value: 1
            },
            {
              name: 'tue',
              label: 'Dienstag',
              active: false,
              value: 2
            },
            {
              name: 'wed',
              label: 'Mittwoch',
              active: false,
              value: 3
            },
            {
              name: 'thu',
              label: 'Donnerstag',
              active: false,
              value: 4
            },
            {
              name: 'fri',
              label: 'Freitag',
              active: false,
              value: 5
            },
            {
              name: 'sat',
              label: 'Samstag',
              active: false,
              value: 6
            },
            {
              name: 'sun',
              label: 'Sonntag',
              active: false,
              value: 7
            }
          ]
        },
        intensity: {
          title: 'Intensität',
          filters: [
            {
              value: 0,
              label: 'weniger als 4 Lektionen pro Woche',
              active: false,
              tooltip: ''
            },
            {
              value: 1,
              label: 'weniger als 8 Lektionen pro Woche',
              active: false,
              tooltip: ''
            },
            {
              value: 2,
              label: 'mehr als 8 Lektionen pro Woche',
              active: false,
              tooltip: ''
            }
          ]
        },
        timeofday: {
          title: 'Tageszeit',
          filters: [
            {
              name: 'Morgen',
              label: 'Morgen',
              active: false,
              value: 0,
              tooltip: 'Start vor 11.00 Uhr'
            },
            {
              name: 'Mittag',
              label: 'Mittag',
              active: false,
              value: 1,
              tooltip: 'Start zwischen 11.00 Uhr und 13:00 Uhr'
            },
            {
              name: 'Nachmittag',
              label: 'Nachmittag',
              active: false,
              value: 2,
              tooltip: 'Start zwischen 13:00 Uhr und 17:00 Uhr'
            },
            {
              name: 'Abend',
              label: 'Abend',
              active: false,
              value: 3,
              tooltip: 'Start nach 17:00 Uhr'
            }
          ]
        },
        categories: {
          title: 'Besonderheiten',
          filters: []
        },
        eventdate: {
          title: 'Kursdatum',
          filters: [
            {
              name: 'startDate',
              label: 'Startdatum',
              value: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
              menu: false,
              active: false
            },
            {
              name: 'endDate',
              label: 'Enddatum',
              value: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
              menu: false,
              active: false
            }
          ]
        },
        zipcode: {
          title: 'Standort',
          filters:
              [{
              name: 'zipCode',
              label: 'PLZ',
              value: null,
              placeholder: '9000',
              menu: false,
              active: false,
              error: false,
              errorMessages: []
            }]
        }
      },
      sortText: {
        title: {
          text: 'Kursname',
          value: 'title'
        },
        location: {
          text: 'Kursanbietende',
          value: 'location'
        },
        date: {
          text: 'Startdatum',
          value: 'date'
        },
        costs: {
          text: 'Kosten',
          value: 'costs'
        }
      },
      filterPanel: [],
      categoryList: [],
      visibleLocations: [],
      clickedEvent: null
    }
  },
  created() {
    window.bindLocationSearch = this.filterSingleLocation
    this.initializeMap()
    this.appliedFilters = this.$store.state.auth.appliedFilters
    if (this.$store.state.auth.eventPage) {
      this.page = this.$store.state.auth.eventPage
    }

    if (this.appliedFilters.location.filters.length > 0 &&
      (this.appliedFilters.location.filters[0] <= 1 || !this.appliedFilters.location.filters[0])) {
      this.appliedFilters.location.filters = []
    }

    if (this.appliedFilters.location.filters.length > 0) {
      this.showMapFilter = true
    }
  },
  mounted() {
    this.getEventsFiltered()
    this.getCategories()
  },
  watch: {
    page: function (val) {
      this.eventPanel = undefined
      if (val < 1) this.page = 1
    }
  },
  methods: {
    getEvents() {
      axios
        .get('/events/')
        .then((response) => {
          this.eventList = response.data
          this.listLoading = false
        })
          .catch((ex) => console.log('request error', ex))
    },
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return true
        case 'sm':
          return true
        case 'md':
          return false
        case 'lg':
          return false
        case 'xl':
          return false
      }
    },
    getCategories() {
      axios
        .get('/events/categories')
        .then((response) => {
          this.categoryList = response.data
          this.categoryList.forEach((category, i) => {
            this.eventFilters.categories.filters.push({
              value: category.id,
              label: category.name,
              description: category.description,
              index: i
            })
          })
        })
          .catch((ex) => console.log('request error', ex))
    },
    getEventsFiltered() {
      this.listLoading = true

      axios
        .get('/events/filtered', {params: {filter: JSON.stringify(this.appliedFilters), sorter: this.sortBy}})
        .then((response) => {
          this.eventList = response.data
          this.listLoading = false
          this.apiError = false
          this.page = 1
        })
        .catch((response) => {
          this.listLoading = false
          this.apiError = true
        })
    },
    showEventDetails(event) {
      // save applied filters to store
      this.$store.state.auth.appliedFilters = JSON.parse(JSON.stringify(this.appliedFilters))
      this.$store.state.auth.eventPage = this.page
      this.$router.push('/kurs/' + event.identifier)
    },
    goToEventWebsite(event) {
      // save applied filters to store
      this.$store.state.auth.appliedFilters = this.appliedFilters
      this.$store.state.auth.eventPage = this.page

      if (event.externalUrl) {
        this.$router.push(event.externalUrl)
      } else {
        const routeData = this.$router.resolve({name: event.EventLocation.Operator.websiteUrl})
        window.open(routeData.href, '_blank')
      }
    },
    removeLocationFilter() {
      this.appliedFilters.location = {
        title: 'Kursort',
        filters: []
      }
      this.$store.state.auth.appliedFilters = JSON.parse(JSON.stringify(this.appliedFilters))
      this.showMapFilter = false
      this.getEventsFiltered()
    },
    removeFilter(filterObj, filter, filterKey) {
      let index = this.appliedFilters[filterKey].filters.findIndex(f => f.value === filter.value)
      this.appliedFilters[filterKey].filters.splice(index, 1)

      if (this.appliedFilters[filterKey].filters.length === 0) {
        delete this.appliedFilters[filterKey]
      }

      index = this.eventFilters[filterKey].filters.findIndex(f => f.value === filter.value)
      this.eventFilters[filterKey].filters[index].active = false
      this.$store.state.auth.appliedFilters = JSON.parse(JSON.stringify(this.appliedFilters))
      this.getEventsFiltered()
    },
    initializeMap() {
      const loader = new Loader({
        apiKey: process.env.VUE_APP_GOOGLE_KEY,
        version: 'weekly',
        libraries: ['geocoder', 'places']
      })

      const mapOptions = {
        center: {
          lat: 47.4234,
          lng: 9.3772
        },
        zoom: 10
      }

      loader
        .load()
        .then((google) => {
          this.google = google
          this.map = new google.maps.Map(document.getElementById('map'), mapOptions)
          this.infoWindow = new google.maps.InfoWindow()

          this.map.addListener('dragend', () => {
            this.updateVisibleLocations()
          })

          this.map.addListener('bounds_changed', () => {
            this.updateVisibleLocations()
          })

          this.getLocations()
        })
        .catch(e => {
          console.log(e)
        })
    },
    getLocations() {
      axios
        .get('/locations')
        .then((response) => {
          this.locationList = response.data
          if (this.locationList && this.locationList.length > 0) {
            this.setLocationMarkers()
          }
        })
          .catch((ex) => console.log('request error', ex))
    },
    setLocationMarkers() {
      this.locationList.forEach((location, index) => {
        if (location.lat) {
          const position = {
            lat: parseFloat(location.lat), lng: parseFloat(location.lon)
          }
          const marker = new this.google.maps.Marker({
            map: this.map,
            position: position,
            _index: index
          })

          marker.addListener('click', () => {
            const markerLocation = this.locationList[marker._index]
            const content = '<h4>' + markerLocation.Operator.name + '</h4><p>' + markerLocation.address + '</p>' +
              '<a onclick="window.bindLocationSearch(' + marker._index + ')">Kurse an diesem Standort anzeigen</a>'
            this.infoWindow.close()
            this.infoWindow.setContent(content)
            this.infoWindow.open(marker.getMap(), marker)
          })

          this.markers.push(marker)
        }
      })
    },
    filterSingleLocation(locationIndex, id = null) {
      if (id) {
        this.appliedFilters.location.filters = [id]
      } else {
        this.appliedFilters.location.filters = [this.locationList[locationIndex].id]
      }
      this.getEventsFiltered()
    },
    filterVisibleLocations() {
      // this.google.maps.event.clearListeners(this.map, 'bounds_changed');
      this.appliedFilters.location.filters = []
      const bounds = this.map.getBounds()
      this.markers.forEach(m => {
        if (bounds.contains(m.getPosition())) {
          this.appliedFilters.location.filters.push(this.locationList[m._index].id)
        }
      })

      // apply null filter to ensure no locations are applied
      if (this.appliedFilters.location.filters.length === 0) this.appliedFilters.location.filters = [null]

      if (this.isMobile()) {
        this.$refs.eventListStart.scrollIntoView({block: 'start', behavior: 'smooth'})
      }

      this.getEventsFiltered()
    },
    sortEvents(sorter) {
      if (this.sortBy.field === sorter) {
        this.sortBy.desc = !this.sortBy.desc
      } else {
        this.sortBy.field = sorter
        this.sortBy.desc = false
      }
      this.getEventsFiltered()
    },
    shortDayStr(weekday) {
      return ['', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'][weekday]
    },
    fullDayStr(weekday) {
      return ['', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'][weekday]
    },
    numberWithDelimiter(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'")
    },
    formatDate(date) {
      if (!date) return null
      return new Date(date).toLocaleDateString('de-CH', {day: 'numeric', month: 'short', year: "numeric"})
    },
    resetFilter() {
      this.appliedFilters = {
        location: {
          title: 'Kursort',
          filters: []
        }
      }
      this.appliedTags = []
      this.$store.state.auth.appliedFilters = JSON.parse(JSON.stringify(this.appliedFilters))

      if (this.isMobile()){
        this.showFilter = false
      }

      this.getEventsFiltered()
    },
    applyZipCodeFilter() {
      this.eventFilters.zipcode.filters[0].error = false
      this.eventFilters.zipcode.filters[0].errorMessages = []

      // check if zipcode is in list of zip codes
      if (ZipCodes.getCoordinatesByZipCode()[this.eventFilters.zipcode.filters[0].value]) {
        this.listLoading = true
        this.showMapFilter = true
        let coords = ZipCodes.getCoordinatesByZipCode()[this.eventFilters.zipcode.filters[0].value]

        const center = new this.google.maps.LatLng(coords.lat, coords.lon)
        this.map.panTo(center);
        this.map.setZoom(12);
        window.setTimeout(this.filterVisibleLocations, 500)

        if (this.isMobile()) {
          this.showFilter = false

          // const top = this.$refs.eventListStart.offsetTop

          this.$refs.eventListStart.scrollIntoView({block: 'start', behavior: 'smooth'})
        }
      } else {
        this.eventFilters.zipcode.filters[0].error = true
        this.eventFilters.zipcode.filters[0].errorMessages = [
          'Geben Sie eine in St. Gallen gültige PLZ ein.'
        ]
      }
      this.$store.state.auth.appliedFilters = JSON.parse(JSON.stringify(this.appliedFilters))
    },
    applyFilter() {
      this.listLoading = true
      // keep location in filter as location filtering is handled seperately via map
      const locationFilters = this.appliedFilters.location
      this.appliedFilters = {}

      for (const key in this.eventFilters) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.eventFilters.hasOwnProperty(key)) {
          const obj = this.eventFilters[key].filters.find(o => o.active === true)

          // only add to filter if at least one available filter is set to active
          if (obj) {
            this.appliedFilters[key] = {
              title: this.eventFilters[key].title,
              filters: []
            }
            this.eventFilters[key].filters.forEach(filter => {
              if (filter.active) {
                this.appliedFilters[key].filters.push(filter)
              }
            })
          }
        }
      }

      this.appliedFilters.location = locationFilters

      if (this.isMobile()) {
        this.showFilter = false
        this.$refs.eventListStart.scrollIntoView({block: 'start', behavior: 'smooth'})
      }
      this.$store.state.auth.appliedFilters = JSON.parse(JSON.stringify(this.appliedFilters))
      this.getEventsFiltered()
    },
    selectDate(filter) {
      filter.menu = false
      filter.active = true
      filter.valueFormatted = this.formatDate(filter.value)
    },
    updateVisibleLocations() {
      const bounds = this.map.getBounds()
      this.visibleLocations = []


      this.markers.forEach(m => {
        if (bounds.contains(m.getPosition())) {
          this.visibleLocations.push(this.locationList[m._index])
        }
      })
    },
    toggleMap() {
      this.showMapFilter = !this.showMapFilter
    },
    generateUrl(url) {
      if (typeof url === 'string') {
        if (url.indexOf('http') < 0) {
          url = 'https://' + url
        }
        return url
      } else {
        return null
      }
    },
    isClicked(event) {
      return event === this.clickedEvent
    }
  },
  computed: {
    ZipCodes() {
      return ZipCodes
    },
    SubsidyCalculation() {
      return SubsidyCalculation
    },
    Format() {
      return Format
    },
    visibleEventList() {
      return this.eventList.slice((this.page - 1) * this.eventsPerPage, this.page * this.eventsPerPage)
    }
  }

}
</script>
<style lang="scss">
$primary: #008334;
$primary-alpha: rgba(0, 131, 52, 0.5);

.theme--light.v-expansion-panels .v-expansion-panel p {
  color: #404040;
  font-size: 0.9rem;
  margin-bottom: 0;
}

.border-light {
  border-left: 1px solid rgba(64, 64, 64, .2);
  border-bottom: 1px solid rgba(64, 64, 64, .2);
  border-right: 1px solid rgba(64, 64, 64, .2);
  -webkit-background-clip: padding-box;
}

.border-top-light {
  border-top: 1px solid rgba(0, 0, 0, .12);
}

h5 {
  font-size: 15px;
}

.event-panels p {
  line-height: 1.5em;
}

.event-card {
  &:focus::before {
    opacity: 0;
  }
  .event-card-text {
    color: #404040;
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 0;

    p {
      margin: 0;
    }
  }



  .v-card__text {
    font-size: 1rem;
  }

  .event-card-subtitle {
    font-size: 1rem;
    margin-bottom: 4px;
  }

  .event-card-title {
    line-height: 1.5rem;
    font-size: 1.3rem;
  }

  .v-icon {
  }

  table {
    td {
      vertical-align: text-top;
    }
  }
}

@media (max-width: 960px) {
  h5 {
    font-size: 0.875rem;
  }

  .v-list-item__content {
    font-size: 0.875rem;
  }

  .filter-chips {
    .v-chip__content {
      font-size: 0.75rem;
    }
  }

  .filter-panels {
    .v-expansion-panel-header {
      font-size: 0.875rem;
    }

    label {
      font-size: 0.875rem;
    }

    .v-btn {
      font-size: 0.875rem;
    }

  }
  .event-card {
    &:focus::before {
      opacity: 0;
    }

    .event-card-text {
      color: #404040;
      font-size: 0.875rem;
      line-height: 1.5rem;
      margin-bottom:  0;
    }

    .v-card__actions {
      .v-btn {
        font-size: 0.875rem;
      }
    }

    .filter-chips {
      .v-chip {
        font-size: 0.875rem;
      }
    }

    .v-card__text {
      font-size: 0.875rem;
    }

    .event-card-subtitle {
      font-size: 0.875rem;
      margin-bottom: 4px;
    }

    .event-card-title {
      line-height: 1.5rem;
      font-size: 1.0rem;
    }

  }
}

.event-card.event-card-active {

    &:focus::before {
      opacity: 0;
    }

    border-color: $primary-alpha;

    /*&:after {
      border-color: $primary-alpha;
    }

    &:before {
      border-color: $primary-alpha;
    }*/

    .event-card-subtitle, .event-card-title {
      color: $primary;
    }

}

/*.theme--light.v-expansion-panels .event-panel-active.v-expansion-panel:not(:first-child)::after {
  border-color: $primary-alpha;
}*/

.v-btn.v-item--active {
  color: $primary;
}

.v-expansion-panel--active > .v-expansion-panel-header.filter-panel-header {
  min-height: 46px;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.event-list-header {
  .v-icon {
    color: #fff;
  }

  .theme--light.v-icon.sorter-active {
    color: #4ca870;
  }
}

.v-btn--filter {
  &:hover {
    color: $primary;
  }
}

.filter-panels {
  .v-expansion-panel {
    border-top: none;

    .v-expansion-panel-content__wrap {
      padding: 24px;
    }

    &:not(:first-child) {
      &::after {
        border-top: none;
      }
    }
  }
}

.theme--light {
  .v-expansion-panel-header {
    .v-icon {
      color: #939393;
    }

    i {
      margin-right: -4px;
    }
  }

  .v-expansion-panel-header {

    i.v-icon {
      //margin-right: -10px;
      //margin-left: 5px;
    }

    td {
      vertical-align: text-top;
      color: #404040;
    }
  }

  .v-expansion-panel.event-panel-active {
    border-color: $primary-alpha;

    /*&:after {
      border-color: $primary-alpha;
    }

    &:before {
      border-color: $primary-alpha;
    }*/

    td {
      color: $primary;
    }

    p {
      color: $primary;
    }

    .v-expansion-panel-content {
      p {
        color: #404040;
      }
    }
  }

  .v-btn--plain:not(.v-btn--active):not(.v-btn--loading):not(:focus):not(:hover) .v-btn__content {
    opacity: 0.78;
  }
}

.google-map {
  height: 350px;
  width: 100%;
}

</style>
