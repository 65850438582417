<template>
  <div>
    <v-bottom-sheet hide-overlay class="cookie-banner" max-width="960" inset v-model="showDialog">
      <v-card>
        <v-card-title class="">
          Datenschutz
        </v-card-title>

        <v-card-text class="pb-1">
          <p class="mt-0">
            Wir verwenden Cookies, um die Nutzererfahrung auf unserer Website zu verbessern.
            <a @click="$emit('showPrivacy')">Datenschutzerklärung</a>
          </p>

        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="default"
            text
            @click="showDialog = false; acceptCookies();"
          >
            Nicht mehr anzeigen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-bottom-sheet>
  </div>

</template>

<script>
import Cookies from 'js-cookie';

export default {
  data() {
    return {
      showDialog: true,
      acceptedCookies: false,
    };
  },
  methods: {
    acceptCookies() {
      this.acceptedCookies = true;
      Cookies.set('essential_cookies_accepted', 'true', {expires: 365});
    },
  },
  mounted() {
    // Check if the user has already accepted essential cookies
    this.acceptedCookies = !!Cookies.get('essential_cookies_accepted');
    if (this.acceptedCookies === true) {
      this.showDialog = false
    }
  },
};
</script>

<style>
.cookie-banner .v-card-title {
  font-size: 1.25rem;
}
</style>
